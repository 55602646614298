.card-bg-img {
    background-image: url('../../assets/bg-profile.9cbdad326c7ddad19136.jpg');
    background-size: cover; /* Ensures the image covers the full size of the element */
    background-position: center;
        border-radius: 2pc;
}


.custom-select {
    border: none !important;
    width: 100% !important;
    border-color: transparent !important;

    /* Remove the border */
}

.custom-select:focus {
    border: none !important;
    width: 100% !important;
    box-shadow: none !important;
    border-color: transparent !important;

}

.custom-select:hover {
    border: none !important;
    width: 100% !important;
    box-shadow: none !important;
    border-color: transparent !important;

}
Select{
    border:none !important;
}
Select:focus{
    border:none !important;
}
Select:active{
    border:none !important;
}
.custom-select__control {
    border: none !important;
    border-color: transparent !important;

    /* Remove the border */
}

.custom-select__control:hover {
    border: none !important;
    border-color: transparent !important;

    /* Remove the border on hover */
}

.custom-select__control--is-focused {
    border: none;
    /* Remove the border when focused */
    box-shadow: none;
    border-color: transparent !important;

    /* Remove the box shadow when focused */
}

.css-13cymwt-control:hover {
    border-color: transparent !important;
}

.round-div {
    background-color: white;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 52%;
    top: 18%;
    border-radius: 5pc;
    border-color: antiquewhite;
}


.social-icons {
    background-color: #e3e2f0;
    margin: 3px;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    text-align: center;
}

.social-icons:hover {
    background-color: #534686;
    color: white;
    cursor: pointer;
}

body {
    font-family: sans-serif;
    background-color: #111;
}

.button {
    display: inline-block;
    margin: 4px 2px;
    background-color: #444;
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button:hover {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
}

.search-container {
    position: relative;
    display: inline-block;
    margin: 4px 2px;
    height: 50px;
    width: 50px;
    vertical-align: bottom;
}

.mglass {
    display: inline-block;
    pointer-events: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
}

.searchbutton {
    position: absolute;
    font-size: 22px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.search:focus+.searchbutton {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
}

.search {
    position: absolute;
    left: 49px;
    /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
}

.search:focus {
    width: 363px;
    /* Bar width+1px */
    padding: 0 16px 0 0;
}

.expandright {
    left: auto;
    right: 49px;
    /* Button width-1px */
}

.expandright:focus {
    padding: 0 0 0 16px;
}


.header-div {
    position: relative;
    width: 100%;
    height: 450px;

}

.custom-card:hover {
    overflow: hidden;
    transform: scale(1.05); 
    /* padding: 0px; */
}
.custom-card .card-header img:hover{
    transform: scale(1.5);
  }