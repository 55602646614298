/*  */
/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 250px;
  height: 100%;
  background-color: #38157c;
  transition: left 0.3s ease-in-out;
  z-index: 11;

}

.sidebar.open {
  left: 0;
}

.sidebar-content {
  padding: 20px;
}

/* Sidebar Toggle Button Styles */
.sidebar-toggle {
  cursor: pointer;
}

/* Media Query for Responsive Behavior */
@media (min-width: 768px) {
  .sidebar {
      display: none;
  }
}

