.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.social-icons {
  font-size: 24px;
}

.social-icons a {
  margin-right: 10px;
}

.custom-input {
  border: none !important;
  outline: none !important;
  height: 50px;
  background-color: white !important;
  opacity: 2 !important;
 
}
.custom-input-border{
  border-right: 1px solid lightgray !important;
  border-radius: 0px !important;
}

.custom-input:focus {
  border: none !important;
  outline: none !important;
  border-color: transparent;
  /* Remove the border color on focus */
  box-shadow: none !important;
}
 
.textprimary{
  color: #38157c !important;
}
.search-button {
  border-radius: 1pc;
} 
.textOpacity{
  background-color: wheat !important;
  opacity: 29 !important;
}

.search-bar {  
  background-color: rgb(170, 185, 220); 
  opacity: 0.85;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 1px;
  z-index: 1;
  position: absolute;
  bottom: -13%;
  width: 80% !important; 
  left: 0;
  right: 0;
}

/* */

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #534686;
}

.tab-content .row>div.col-xl-4:nth-child(1) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 100000ms;
}

.tab-content .row>div.col-xl-4:nth-child(2) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 200000ms;
}

.tab-content .row>div.col-xl-4:nth-child(3) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 300000ms;
}

.tab-content .row>div.col-xl-4:nth-child(4) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 400000ms;
}

.tab-content .row>div.col-xl-4:nth-child(5) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 500000ms;
}

.tab-content .row>div.col-xl-4:nth-child(6) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 600000ms;
}

.tab-content .row>div.col-xl-4:nth-child(7) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 700000ms;
}

.tab-content .row>div.col-xl-4:nth-child(8) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 800000ms;
}

.tab-content .row>div.col-xl-4:nth-child(9) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 900000ms;
}

.tab-content .row>div.col-xl-4:nth-child(10) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1000000ms;
}

.tab-content .row>div.col-xl-4:nth-child(11) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1100000ms;
}

.tab-content .row>div.col-xl-4:nth-child(12) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1200000ms;
}

.tab-content .row>div.col-xl-4:nth-child(13) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1300000ms;
}

.tab-content .row>div.col-xl-4:nth-child(14) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1400000ms;
}

.tab-content .row>div.col-xl-4:nth-child(15) {
  animation-fill-mode: both;
  animation: fadeIncustom 0.5s linear 1500000ms;
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .row .col-xl-4 {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.rtl .email-wrap .email-right-aside .email-body .row .col-xl-4 {
  border: none;
}

.box-layout .box-col-33 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}


.modal .modal-body .card {
  box-shadow: none !important;
}

.default-according .card {
  margin-bottom: 0;
  box-shadow: none !important;
  border: 1px solid rgba(83, 70, 134, 0.1019607843);
  overflow: hidden;
}

.default-according .card+.card {
  margin-top: 10px;
}

.default-according .card .btn-link {
  font-weight: 500;
  color: #2B2B2B;
  font-size: 16px;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;
}

.default-according .card .btn-link:focus,
.default-according .card .btn-link:hover {
  text-decoration: none;
}

.default-according .card .card-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid rgba(83, 70, 134, 0.1019607843);
}

.default-according .card .card-header i {
  position: initial;
  font-size: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.default-according .card .card-header h3 {
  margin-top: 2px;
}

.default-according .card .card-body {
  border-top: none !important;
  padding: 20px;
}

.default-according .card .card-body h5 {
  font-size: 14px;
  margin-bottom: 5px;
}

.default-according .card .card-body h6 {
  color: #9993B4;
  font-size: 12px;
  margin-bottom: 5px;
}

.default-according .card .card-body ul.rating li {
  display: inline-block;
}

@media (max-width: 480px) {
  .default-according .card .card-header {
    padding-right: 32px;
  }

  .default-according .card .card-header h5 {
    font-size: 16px;
  }

  .default-according.style-1 button:before {
    right: 10px;
  }
}

.card {
  margin-bottom: 30px;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
}

.card:hover {
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.card .card-header {
  padding: 25px;
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  background-color: transparent;
}

.card .card-header.dflex {
  display: flex;
  align-items: center;
}

.card .card-header .center-content {
  margin-left: auto;
  margin-right: auto;
}

.card .card-header .center-content p {
  color: #9993B4;
  margin-bottom: 0;
}

.card .card-header.card-no-border {
  border-bottom: none !important;
}

.card .card-header h3 {
  color: #275070;
  margin-bottom: 0;
}

.card .card-header>span {
  font-size: 12px;
  color: rgba(43, 43, 43, 0.7);
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
}

.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 25px;
  top: 24px;
  display: inline-block;
  float: right;
  position: absolute;
  z-index: 0;
}

.card .card-header .card-header-right .card-option {
  text-align: right;
  width: 26px;
  height: 26px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.card .card-header .card-header-right .card-option li {
  display: inline-block;
}

.card .card-header .card-header-right .card-option li i {
  margin: 0 5px;
}

.card .card-header .card-header-right .card-option li:first-child {
  background-color: rgba(39, 80, 112, 0.1);
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.card .card-header .card-header-right .card-option li:first-child>div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.card .card-header .card-header-right .card-option li:first-child i {
  transition: all 1s ease;
  color: #275070;
}

.card .card-header .card-header-right .card-option li:first-child i.icon-angle-double-right {
  color: #2B2B2B;
}

.card .card-header .card-header-right .card-option li:first-child i.icon-angle-double-right::before {
  content: "\e661" !important;
}

.card .card-header .card-header-right i {
  cursor: pointer;
  color: #2c323f;
  line-height: 20px;
}

.card .card-header .card-header-right i.icofont-refresh {
  font-size: 13px;
}

.card .card-body {
  padding: 25px;
  background-color: transparent;
}

.card .card-body p:last-child {
  margin-bottom: 0;
}

.card .sub-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
}

.card .card-footer {
  background-color: transparent;
  border-top: 1px solid rgba(83, 70, 134, 0.1019607843);
  padding: 25px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  align-items: center;
  justify-content: center;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: var(--theme-default);
  font-size: 20px;
}

.card.full-card {
  position: fixed;
  top: 0;
  z-index: 99999;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #efefef;
  width: calc(100vw - 12px);
  height: 100vh;
}

.card.full-card .card-body {
  overflow: auto;
}

.custom-card {
  overflow: hidden;
  padding: 20px;
}

.custom-card .card-header {
  padding: 0;
}

.custom-card .card-header img {
  transform: scale(1.5);
}

.custom-card .card-profile {
  text-align: center;
}

.custom-card .card-profile img {
  height: 130px !important;
  padding: 7px;
  background-color: #d7e2e9;
  z-index: 0;
  position: relative;
}

.custom-card .card-social {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-card .card-social li {
  display: inline-block;
}

.custom-card .card-social li:nth-child(n+2) {
  margin-left: 10px;
}

.custom-card .card-social li a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(39, 80, 112, 0.1);
  transition: all 0.3s ease;
  color: #275070;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-card .card-social li:hover a {
  background-color: #275070;
  color: #ffffff;
}

.custom-card .profile-details h2 {
  font-weight: 600;
  color: #2B2B2B;
}

.custom-card .profile-details h6 {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #9993B4;
  font-size: 14px;
  font-weight: 500;
}

.custom-card .card-footer {
  padding: 0;
}

.custom-card .card-footer>div {
  padding: 15px;
  text-align: center;
}

.custom-card .card-footer>div+div {
  border-left: 1px solid rgba(83, 70, 134, 0.1019607843);
}

.custom-card .card-footer>div h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}

.custom-card .card-footer>div h6 {
  font-size: 13px;
  color: #9993B4;
}

.custom-card .card-footer>div i {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 15px;
}

.custom-card .card-footer>div .m-b-card {
  margin-bottom: 10px;
}

.ProfileCard {
  position: relative;
  padding: 10px;
  border: 1px solid #cccccc !important;
  margin-bottom: 10px;
  border-radius: 10px;
}

.blog-single .blog-box.blog-details .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bookmark-tabcontent .details-bookmark .bookmark-card {
  position: relative;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  padding: 3px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon i {
  color: #ffffff;
  font-size: 16px;
  vertical-align: text-bottom;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon.favourite i {
  color: #fed112;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data {
  width: 100%;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data .content-general {
  display: none;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  transition: 0.5s;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: left;
  padding: 15px;
  border-top: 1px solid rgba(83, 70, 134, 0.1019607843);
  position: relative;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark p {
  animation: displayTransition 1s;
  font-size: 13px;
  opacity: 1;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block {
  display: none;
  margin-top: 35px;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li {
  display: inline-block;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li+li {
  margin-left: 5px;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li svg {
  width: 16px;
  height: 16px;
  color: #898989;
}

.custom-card .card-profile {
  text-align: center;
}

.custom-card .card-profile img {
  height: 110px;
  padding: 7px;
  background-color: #d7e2e9;
  z-index: 0;
  position: relative;
}

.custom-profile .card-profile {
  position: relative;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.custom-profile .card-profile img {
  background-color: transparent;
}

.general-widget .custom-profile .card-profile img {
  height: 200px;
}

.box-layout .page-wrapper .page-body-wrapper .custom-card .card-profile img,
.box-layout.page-wrapper .page-body-wrapper .custom-card .card-profile img {
  height: 130px;
  top: -17px;
}

.box-layout .general-widget .custom-profile .card-profile img {
  height: 200px;
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-profile img {
  background-color: #1d1930;
}

body.dark-only .page-wrapper .page-body-wrapper .custom-card .profile-details h2 {
  color: rgba(255, 255, 255, 0.8);
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .profile-details h6 {
  color: #b4b2ba;
}

.custom-profile .profile-details {
  margin-top: 20px;
}

.custom-profile .profile-details h4 {
  font-size: 20px;
  color: #2B2B2B;
}

.custom-profile .profile-details h6 {
  font-size: 16px;
  color: #9993B4;
  margin-bottom: 0;
}


.custom-profile .card-footer {
  background-color: transparent;
  padding: 0 !important;
}

.custom-profile .card-footer h6 {
  font-size: 16px;
}

.custom-profile .card-footer h5 {
  font-size: 20px;
}

.custom-profile .card-footer>div {
  text-align: center;
  margin: 0;
}

.custom-profile .card-footer>div h6 {
  color: #9993B4;
}

.custom-profile .card-footer>div h5 {
  margin-bottom: 0;
}

.custom-profile .card-footer>div div {
  padding-top: 30px;
  padding-bottom: 30px;
}

.custom-profile .card-footer>div div+div {
  border-left: 1px solid rgba(83, 70, 134, 0.1019607843);
}

@media screen and (max-width: 1660px) and (min-width: 1366px) {
  .custom-profile .card-footer>div div {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .custom-profile .card-footer h6 {
    font-size: 14px;
  }

  .custom-profile .card-footer h5 {
    font-size: 18px;
  }
}

.custom-profile .card-footer>div {
  margin: 0;
}

.custom-profile .card-footer>div {
  margin: 0;
}

.custom-profile .card-footer>div div {
  padding: 28px 10px;
}

.custom-profile .card-footer h6 {
  font-size: 14px;
}

.custom-profile .card-footer h6 {
  font-size: 14px;
}

.custom-profile .card-footer h5 {
  font-size: 16px;
}

.custom-profile .card-footer>div div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.features-faq .card-body,
.features-faq .card-footer {
  padding: 20px;
}

.projectdetails .card .card-footer {
  padding: 20px;
}

.box-layout .default-page .card .card-footer,
.box-layout .ecommerce-page .card .card-footer {
  padding: 20px;
}

.box-layout .custom-profile .card-footer>div {
  margin: 0;
}

.box-layout .custom-profile .card-footer>div div {
  padding: 28px 10px;
}

.box-layout .custom-profile .card-footer h6 {
  font-size: 14px;
}

body.dark-only .custom-profile .card-footer>div div+div {
  border-color: #2e2846;
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-footer {
  background-color: #1d1930;
  border-top: 1px solid #2e2846;
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer>div h6 {
  color: #b4b2ba;
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer>div+div {
  border-left: 1px solid #322722;
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card .card-footer .row .col:nth-child(n+2) {
  border-color: #2e2846;
}

body.dark-only .user-card .card-footer>div:first-child {
  border-right: 1px solid #151122;
}

body.dark-only .user-card .card-footer .user-footer h6 {
  color: #b4b2ba;
}

body.dark-only .user-card .card-footer .user-footer svg path,
body.dark-only .user-card .card-footer .user-footer svg rect {
  color: #b4b2ba;
}

.rtl .custom-profile .card-footer>div div+div {
  border-left: unset;
  border-right: 1px solid rgba(83, 70, 134, 0.1019607843);
}

.rtl .social-widget-card .card-footer .row .col:nth-child(n+2) {
  border-right: 1px solid rgba(83, 70, 134, 0.1019607843);
  border-left: unset;
}

.rtl .custom-card .card-footer>div+div {
  border-right: 1px solid rgba(83, 70, 134, 0.1019607843);
  border-left: unset !important;
}

.rtl body.dark-only .custom-card .card-footer>div+div {
  border-right: 1px solid #2e2846;
}

.landing-page .counter-sec {
  background-color: rgba(39, 80, 112, 0.05);
}

.landing-page .counter-sec .counter-box {
  text-align: center;
  border: 1px solid rgba(83, 70, 134, 0.1019607843);
  padding: 25px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.landing-page .counter-sec .counter-box .count-number {
  background-color: rgba(39, 80, 112, 0.1);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  transition: all 0.5s ease;
}

.landing-page .counter-sec .counter-box .count-number h3 {
  font-size: 40px;
  margin-bottom: unset;
  color: #275070;
  transition: all 0.5s ease;
}

.landing-page .counter-sec .counter-box .count-detail h4 {
  text-transform: capitalize;
}

.landing-page .counter-sec .counter-box .count-detail p {
  font-size: 16px;
  color: #9993B4;
  margin-bottom: unset;
}

.landing-page .counter-sec .counter-box:hover .count-number {
  background-color: #275070;
}

.landing-page .counter-sec .counter-box:hover .count-number h3 {
  color: #955670;
}

@media (max-width: 1366px) {


  .landing-page .counter-sec .counter-box .count-number {
    margin-bottom: 15px;
    width: 70px;
    height: 70px;
  }

  .landing-page .counter-sec .counter-box .count-number h3 {
    font-size: 30px;
  }

  .landing-page .counter-sec .counter-box .count-detail h4 {
    font-size: 22px;
  }

  .landing-page .counter-sec .counter-box .count-detail p {
    font-size: 14px;
  }
}

.rtl #lg-counter {
  padding-right: 20px;
  padding-left: unset;
  float: right;
}