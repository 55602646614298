
.breadcrumb {
    background-color: #f5f5f5;
    padding: 8px 15px;
    border-radius: 4px;
  }
  
  .breadcrumb-item {
    display: inline-block;
    font-size: 14px;
    color: #981616;
  }
  
  .breadcrumb-item:not(:last-child)::after {
    content: "/";
    margin: 0 8px;
    color: #961414;
  }
   
  .breadcrumb-item:last-child {
    font-weight: bold;
  } 
  a{
    text-decoration:none !important;
    font-size: 20px;
    color: #38157c !important;
    font-weight: 500;
  }
  nav{
    background-color: rgb(248, 248, 248) !important; 
  }
  