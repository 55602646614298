.ag-courses_box {
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;


}
/* .ag-courses_box:hover{
    color:#fff !important;
} */

.ag-courses_item {
    -ms-flex-preferred-size: calc(33.33333% - 30px);
    flex-basis: calc(33.33333% - 30px);
    margin: 0 15px 30px;
    overflow: hidden;
    border: 1px solid #cbcaca;
    border-radius: 28px;
    max-height: 210px;
}

.ag-courses-item_link {
    display: block;
    padding: 30px 20px;
    background-color: #fff;

    overflow: hidden;
    text-decoration: none;

    position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
    text-decoration: none;
    color: #fff;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
}

.ag-courses-item_title {
    min-height: 90px;
    margin: 0 0 25px;
    overflow: hidden;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    z-index: 2;
    position: relative;
}

.ag-courses-item_date-box {
    font-size: 18px;
    color: #000;
    z-index: 2;
    position: relative;
}
.truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show before truncating */
    -webkit-box-orient: vertical;
  }
.ag-courses-item_date {
    font-weight: bold;
    color: #f9b234;
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
}

.ag-courses-item_bg {
    height: 128px;
    width: 128px;
    background-color: #f7d394;
    z-index: 1; 
    position: absolute;
    top: -75px;
    right: -75px;
    border-radius: 50%;

    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
    background-color: #b3ecbf;
}
 
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
    background-color: #efb5a0;   
} 
 
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
    background-color: #cc9ff9; 
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg { 
    background-color: #eeaed4;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
    background-color: #aeadf5;
}



@media only screen and (max-width: 979px) {
    .ag-courses_item {
        -ms-flex-preferred-size: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
    }

    .ag-courses-item_title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .ag-format-container {
        /* width: 96%; */
    }

}

@media only screen and (max-width: 639px) {
    .ag-courses_item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }

    .ag-courses-item_title {
        min-height: 72px;
        line-height: 1;

        font-size: 15px;
    }

    .ag-courses-item_link {
        padding: 22px 40px;
    }

    .ag-courses-item_date-box {
        font-size: 16px;
    }
}